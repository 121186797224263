import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/Seo'
import NavbarGlobal from '../components/NavbarGlobal'
import FooterGlobal from '../components/FooterGlobal'
import ToggleOffer from '../components/ToggleOffer'
import { FaCheckCircle } from "react-icons/fa"

export default function MasazLeczniczy() {
  return (

    <div className='layout'>

      <Seo 
        title="Masaż Leczniczy"
        description="Masaż leczniczy z elementami fizjoterapii dla żyjących w napięciu, którzy chcą rozluźnić przeciążone mięśnie i podnieść komfort swojego życia."
        keywords="masaż, ból pleców"
        url="https://www.fizjopersonalny.pl/masaz-leczniczy"
      />

      <div className='header_gradient'>
        <NavbarGlobal />
        <div className='offer_box'>

          <div className='offer_intro_header'>
            <h1>Masaż leczniczy</h1>
            <span>Głębokie rozluźnienie dla spiętych mięśni</span>
            <p>Masaż leczniczy z elementami fizjoterapii dla żyjących w napięciu, którzy chcą rozluźnić przeciążone mięśnie i podnieść komfort swojego życia.</p>
            <ul>
              <li><FaCheckCircle />ulga w bólu</li>
              <li><FaCheckCircle />kameralna atmosfera</li>
              <li><FaCheckCircle />indywidualnie dobrane techniki</li>
            </ul>
            <div className='offer_intro_buttons'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
              <Link to="#wiecej" className='button offer_intro_button'>dowiedz się więcej</Link>
            </div>
          </div>

          <div className='offer_intro_image'>
            <StaticImage src="../images/offer/masaz-leczniczy.jpg" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
          </div>

        </div>
      </div>
      
      <div className='content'>
      
        <section id="wiecej">
          <div className='offer_content'>
            <h2>Masaż leczniczy poprawia komfort codziennego życia</h2>
            <span>Wyeliminuj szkodliwe efekty działania stresu</span>
            <p>Jesteś na tej stronie ponieważ odczuwasz zbyt duże napięcie w ciele, które być może doprowadza Cię do bólu. Usztywniona szyja uniemożliwia Ci normalne prowadzenie samochodu? Kłujący ból pod łopatką skutecznie przeszkadza Ci podczas pracy? Nadmierny stres odkłada się w karku i napina barki?</p>

            <div className='offer_client'>
              <ul>
                <li>
                  <h3>napięty kark</h3>
                  <StaticImage src="../images/offer/bol-karku.jpg" alt="Masaż leczniczy na ból karku"/>
                </li>
                <li>
                  <h3>ból lędźwi</h3>
                  <StaticImage src="../images/offer/bol-plecow.jpg" alt="Masaż leczniczy na ból pleców"/>
                </li>
                <li>
                  <h3>usztywniona szyja</h3>
                  <StaticImage src="../images/offer/bol-szyi.jpg" alt="Masaż leczniczy na ból szyi"/>
                </li>
              </ul>
            </div>
            
            <p>Jeśli odpowiadasz "TAK" na jakiekiolwiek z powyższych pytań to znak, że Twoje ciało potrzebuje rozluźnienia. Możesz je osiągnąc wykorzystując wpływ manualnego masażu leczniczego, uzupełnionego o indywidualnie dobrane metody i techniki fizjoterapeutyczne.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content offer_content_background'>
            <h2>Masaż leczniczy w opinii pacjentów</h2>
            <span>Rekomendacje osób, które już sprawdziły</span>
            <p>Przedstawione poniżej rekomendacje stanowią część opinii. Z całością zapoznasz się tutaj: <a href="https://goo.gl/maps/4H6dS7wtz8Ue3JSh9" target="_blank" rel="noreferrer">Google Maps</a>, <a href="https://www.facebook.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Facebook</a>, <a href="https://www.instagram.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Instagram</a> czy <a href="https://www.znanylekarz.pl/lukasz-kalenczuk/fizjoterapeuta/warszawa" target="_blank" rel="noreferrer">Znany Lekarz</a>.</p>

            <div className='offer_feed'>
              <ul>
                <li>
                  <StaticImage src="../images/feed/gm-14.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/fb-5.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/gm-18.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
              </ul>
            </div>

            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content'>
            <h2>Masaż leczniczy z elementami fizjoterapii</h2>
            <span>Połączona siła sprawdzonych technik i metod</span>
            <p>Masaż leczniczy połączony z technikami i metodami wykorzystywanymi w fizjoterapii pozwala osiągać jeszcze lepsze efekty. Zastosowanie dodatkowego bodźca skuteczniej uwalnia tkanki od napięć. Dzięki sięganiu głębiej i szerzej, osiągasz trwalsze rozluźnienie.</p>

            <div className='offer_massage_method'>
              <ul>
                <li>
                  <h3>Fizjoterapia powięziowa</h3>
                  <StaticImage src="../images/offer/terapia-manualna.jpg" alt="Fizjoterapia - terapia powięziowa"/>
                  <ToggleOffer title="Przeczytaj opis metody">
                    <p>Wykorzystuje rękoczyny, które przywracają prawidłowy ślizg pomiędzy tkankami powięziowymi, co poprawia ich elastyczność. Ślizg ten zaburzany jest m.in. przez stres, zmniejszone nawodnienie, brak (lub nadmiaru) aktywności fizycznej czy pracę siedzącą. Powięź jest jedyną tkanką, która zmienia swoją konsystencję pod wpływem nacisku i jest zdolna do odzyskiwania elastyczności, gdy poddamy ją manipulacji.</p>
                  </ToggleOffer>
                </li>
                <li>
                  <h3>Terapia punktów spustowych</h3>
                  <StaticImage src="../images/offer/terapia-powieziowa.jpg" alt="Fizjoterapia - punkty spustowe"/>
                  <ToggleOffer title="Przeczytaj opis metody">
                    <p>Punkty spustowe to obszary wyczuwalne pod palcem jako grudki, zgrubienia czy napięte pasma, które po uciśnięciu bardzo bolą. Tworzą się jako efekt przeciążeń układu ruchu i zaburzeń biochemicznych. Potrafią generować nieznośne bóle głowy czy pleców. Zastosowanie tej techniki polega na uciskaniu specyficznych obszarów ciała w celu dezaktywacji punktów spustowych, co powiązane jest z uzyskaniem ulgi.</p>
                  </ToggleOffer>
                </li>
                <li>
                  <h3>Fizjoterapia narzędziowa</h3>
                  <StaticImage src="../images/offer/terapia-narzedziowa.jpg" alt="Fizjoterapia - terapia narzędziowa"/>
                  <ToggleOffer title="Przeczytaj opis metody">
                    <p>Fizjoterapia z wykorzystaniem narzędzi skupia się na takim doborze instrumentów, aby zwiększyć efektywnośc terapii. Umożliwia zastosowanie intensywniejszego i bardziej precyzyjnego bodźca, kiedy pojawia się taka potrzeba. Wykorzystywane są tutaj: narzędzia ze stali chirurgicznej, elastyczne taśmy (kinesiology taping), aplikator wieloigłowy, bańki chińskie, gumy oporowe czy piłki do autoterapii.</p>
                  </ToggleOffer>
                </li>
              </ul>
            </div>
            
            <p>Masaż leczniczy to skuteczna metoda niwelowania nadmiernych napięć w ciele. Zastosowanie dodatkowych technik i metod fizjoterapeutycznych sprawia, że osiągane są jeszcze głębsze poziomy rozluźnienia. Przekonaj się jak mocno Twoje ciało potrafi się rozlużnić.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>

      </div>
      <FooterGlobal />
    </div>

  )
}